import { action, flow, makeAutoObservable, observable } from 'mobx'
import api from '~/src/api'

class SubscriptionPlanStore {
  isSubmitting = false
  error = null

  constructor(rootStore) {
    this.rootStore = rootStore
    makeAutoObservable(this, {
      isSubmitting: observable,
      error: observable,
      reset: action,
      listSubscriptionPlan: flow,
    })
  }

  reset() {
    this.isSubmitting = false
    this.error = null
  }

  *listSubscriptionPlan() {
    this.isSubmitting = true
    const { commonStore } = this.rootStore
    const { token } = commonStore
    try {
      const response = yield api.listSubscriptionPlan(token)
      this.error = null
      this.isSubmitting = false
      return Promise.resolve(response.data)
    } catch (error) {
      console.log(error)
      this.isSubmitting = false
      this.error = {
        errorCodes: error.response.data.errorCodes,
        messages: error.response.data.messages,
      }
      return Promise.reject()
    }
  }
}

export default SubscriptionPlanStore
