import React from 'react'
import { useIntl } from 'react-intl'
import styled from 'styled-components'
import messages from '~/src/messages'

const FooterWrapper = styled.div`
  background: #333333;
  width: 100%;
`

const FooterDetail = styled.div`
  width: 80%;
  height: 64px;
  margin: 0 auto;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
`

const Copyright = styled.div`
  color: #fff;
  padding: 8px;
`

const Footer = () => {
  const intl = useIntl()
  return (
    <FooterWrapper>
      <FooterDetail>
        <Copyright>{intl.formatMessage(messages.copyright)}</Copyright>
      </FooterDetail>
    </FooterWrapper>
  )
}

export default React.memo(Footer)
