import { DownOutlined, MenuOutlined } from '@ant-design/icons'
import { Avatar, Button, Dropdown, Menu } from 'antd'
import { observer } from 'mobx-react'
import React, { useMemo } from 'react'
import { useIntl } from 'react-intl'
import MediaQuery from 'react-responsive'
import { Link, useHistory } from 'react-router-dom'
import styled from 'styled-components'
import { useStores } from '~/src/hooks'
import messages from '~/src/messages'

const AppBar = styled.div`
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: center;
  padding: 0 16px;
  box-shadow: 1px 1px 1px #888888;
  background: #2e94c1;
  height: 64px;
`

const AppBarLeftSideWrapper = styled.div`
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  flex-grow: 1;
`

const AppBarRightSideWrapper = styled.div`
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
`

const Row = styled.div`
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
`

const Title = styled.div`
  color: white;
`

const ApplicationBar = ({ onMenuButtonClick }) => {
  const intl = useIntl()
  const { authStore, commonStore } = useStores()
  const history = useHistory()
  const { user } = commonStore
  const { locale } = intl

  const avatarText =
    user && user.firstNameEn && user.lastNameEn
      ? user.firstNameEn[0] + user.lastNameEn[0]
      : ''

  const handleOnLogoutClick = () => {
    authStore.logout()
    history.push(`/login?locale=${locale}`)
  }

  const appTitle = useMemo(() => {
    if (onMenuButtonClick) {
      return (
        <>
          <MediaQuery minWidth={768}>
            <Link to={`/?locale=${locale}`}>
              <Title>Scribo Admin System</Title>
            </Link>
          </MediaQuery>
          <MediaQuery maxWidth={767}>
            <Button
              type="primary"
              icon={<MenuOutlined />}
              onClick={() => onMenuButtonClick()}
            />
          </MediaQuery>
        </>
      )
    }
    return (
      <Link to={`/?locale=${locale}`}>
        <Title>Scribo Admin System</Title>
      </Link>
    )
  }, [onMenuButtonClick, locale])
  const dropdownMenu = (
    <Menu>
      <Menu.Item onClick={handleOnLogoutClick}>
        {intl.formatMessage(messages.logout)}
      </Menu.Item>
    </Menu>
  )

  return (
    <AppBar>
      <AppBarLeftSideWrapper>{appTitle}</AppBarLeftSideWrapper>
      <AppBarRightSideWrapper>
        <Dropdown trigger={['hover']} overlay={dropdownMenu}>
          <Row>
            <Avatar>{avatarText}</Avatar>
            <DownOutlined />
          </Row>
        </Dropdown>
      </AppBarRightSideWrapper>
    </AppBar>
  )
}

export default observer(ApplicationBar)
