import axios from 'axios'
import { action, flow, makeAutoObservable, observable } from 'mobx'

const BASE_URL = process.env.REACT_APP_BASE_URL
class SubscriptionPlanStore {
  isSubmitting = false
  error = null

  constructor(rootStore) {
    this.rootStore = rootStore
    makeAutoObservable(this, {
      isSubmitting: observable,
      error: observable,
      reset: action,
      getClinicCurrentSubscription: flow,
      getClinicCurrentAdditionalUserSubscription: flow,
    })
  }
  reset() {
    this.isSubmitting = false
    this.error = null
  }

  *getClinicCurrentSubscription(clinicId) {
    this.isSubmitting = true
    const { commonStore } = this.rootStore
    const { token } = commonStore
    try {
      const response = yield axios({
        method: 'get',
        url: `${BASE_URL}/admin/clinic/${clinicId}/subscription/basic/current`,
        headers: {
          Authorization: `JWT ${token}`,
        },
      })
      this.error = null
      this.isSubmitting = false
      return Promise.resolve(response.data)
    } catch (error) {
      console.log(error)
      this.error = {
        errorCodes: error.response.data.errorCodes,
        messages: error.response.data.messages,
      }
      this.isSubmitting = false
      return Promise.reject()
    }
  }

  *getClinicCurrentAdditionalUserSubscription(clinicId) {
    this.isSubmitting = true
    const { commonStore } = this.rootStore
    const { token } = commonStore
    try {
      const response = yield axios({
        method: 'get',
        url: `${BASE_URL}/admin/clinic/${clinicId}/subscription/additionalUser/current`,
        headers: {
          Authorization: `JWT ${token}`,
        },
      })
      this.error = null
      this.isSubmitting = false
      return Promise.resolve(response.data)
    } catch (error) {
      console.log(error)
      this.error = {
        errorCodes: error.response.data.errorCodes,
        messages: error.response.data.messages,
      }
      this.isSubmitting = false
      return Promise.reject()
    }
  }
}

export default SubscriptionPlanStore
