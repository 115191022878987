import { action, flow, makeAutoObservable, observable } from 'mobx'
import moment from 'moment'
import queryString from 'query-string'
import api from '~/src/api'

const BASE_URL = process.env.REACT_APP_BASE_URL
const STRIPE_WEBHOOK_URL = process.env.REACT_APP_STRIPE_WEBHOOK_URL

const params = queryString.parse(window.location.search)
const defaultLocale = params.locale || 'en'
class CommonStore {
  user = undefined
  token = localStorage.getItem('token')
  tokenExpAt = localStorage.getItem('tokenExpAt')
  locale = defaultLocale

  constructor(rootStore) {
    this.rootStore = rootStore
    makeAutoObservable(this, {
      user: observable,
      token: observable,
      tokenExpAt: observable,
      locale: observable,
      setUser: action,
      updateUser: action,
      removeUser: action,
      setLocale: action,
      checkApiServerHealth: flow,
      checkWsServerHealth: flow,
      checkStripeWebhookServerHealth: flow,
      checkOrenServerHealth: flow,
      resetStoresWhileLeavingClinic: action,
    })
  }

  setUser(user, rememberMe) {
    const { token, tokenExpAt } = user
    if (rememberMe) {
      localStorage.setItem('token', token)
      localStorage.setItem('tokenExpAt', tokenExpAt)
    } else {
      localStorage.setItem('token', token)
      localStorage.setItem('tokenExpAt', moment().add(3, 'hours').format())
    }
    this.user = user
    this.token = token
    this.tokenExpAt = tokenExpAt
  }

  updateUser(user) {
    this.user = user
  }

  removeUser() {
    localStorage.removeItem('token')
    localStorage.removeItem('tokenExpAt')
    this.user = null
    this.token = null
    this.tokenExpAt = null
  }

  setLocale(locale) {
    this.locale = locale
  }

  *checkApiServerHealth() {
    this.isSubmitting = true
    try {
      yield api.checkHealth(this.token, BASE_URL)
      this.error = null
      this.isSubmitting = false
      return Promise.resolve()
    } catch (error) {
      console.log(error)
      this.isSubmitting = false
      this.error = {
        errorCodes: error.response.data.errorCodes,
        messages: error.response.data.messages,
      }
      return Promise.reject()
    }
  }

  *checkStripeWebhookServerHealth() {
    this.isSubmitting = true
    try {
      yield api.checkHealth(this.token, STRIPE_WEBHOOK_URL)
      this.error = null
      this.isSubmitting = false
      return Promise.resolve()
    } catch (error) {
      console.log(error)
      this.isSubmitting = false
      this.error = {
        errorCodes: error.response.data.errorCodes,
        messages: error.response.data.messages,
      }
      return Promise.reject()
    }
  }

  *checkOrenServerHealth() {
    this.isSubmitting = true
    try {
      yield api.checkOrenServerHealth(this.token)
      this.error = null
      this.isSubmitting = false
      return Promise.resolve()
    } catch (error) {
      console.log(error)
      this.isSubmitting = false
      this.error = {
        errorCodes: error.response.data.errorCodes,
        messages: error.response.data.messages,
      }
      return Promise.reject()
    }
  }
}
export default CommonStore
