import { defineMessages } from 'react-intl'

export default defineMessages({
  companyName: { id: 'companyName', defaultMessage: 'Scribo' },
  copyright: { id: 'copyright', defaultMessage: '版權所有 © Scribo' },
  adminPanel: { id: 'adminPanel', defaultMessage: '管理員平台' },
  password: { id: 'password', defaultMessage: '密碼' },
  newPassword: { id: 'newPassword', defaultMessage: '新密碼' },
  confirmPassword: { id: 'confirmPassword', defaultMessage: '確認密碼' },
  superuser: { id: 'superuser', defaultMessage: '超級用戶' },
  admin: { id: 'admin', defaultMessage: '管理員' },
  user: { id: 'user', defaultMessage: '用戶' },
  username: { id: 'username', defaultMessage: '用戶名' },
  email: { id: 'email', defaultMessage: '電郵' },
  role: { id: 'role', defaultMessage: '角色' },
  createdBy: { id: 'createdBy', defaultMessage: '創建者' },
  createdAt: { id: 'createdAt', defaultMessage: '創建時間' },
  updatedBy: { id: 'updatedBy', defaultMessage: '更新者' },
  updatedAt: { id: 'updatedAt', defaultMessage: '更新時間' },
  name: { id: 'name', defaultMessage: '名稱' },
  phoneNumber: { id: 'phoneNumber', defaultMessage: '電話號碼' },
  nameInEnglish: { id: 'nameInEnglish', defaultMessage: '姓名(英文)' },
  nameInChinese: { id: 'nameInChinese', defaultMessage: '姓名(中文)' },
  address: { id: 'address', defaultMessage: '地址' },
  adminList: { id: 'adminList', defaultMessage: '管理員列表' },
  createAdmin: { id: 'createAdmin', defaultMessage: '創建管理員' },
  userList: { id: 'userList', defaultMessage: '用戶列表' },
  createUser: { id: 'createUser', defaultMessage: '創建用戶' },
  forgotPassword: { id: 'forgotPassword', defaultMessage: '忘記密碼' },
  resetPassword: { id: 'resetPassword', defaultMessage: '重設密碼' },
  loginPageTitle: { id: 'loginPageTitle', defaultMessage: '登入' },
  forgotPasswordPageTitle: {
    id: 'forgotPasswordPageTitle',
    defaultMessage: '忘記密碼',
  },
  resetPasswordPageTitle: {
    id: 'resetPasswordPageTitle',
    defaultMessage: '重設密碼',
  },
  adminDetailPageTitle: {
    id: 'adminDetailPageTitle',
    defaultMessage: '管理員詳情',
  },
  adminListPageTitle: {
    id: 'adminListPageTitle',
    defaultMessage: '管理員列表',
  },
  createAdminPageTitle: {
    id: 'createAdminPageTitle',
    defaultMessage: '創建管理員',
  },
  createUserPageTitle: {
    id: 'createUserPageTitle',
    defaultMessage: '創建用戶',
  },
  actions: { id: 'actions', defaultMessage: '行動' },
  edit: { id: 'edit', defaultMessage: '更改' },
  delete: { id: 'delete', defaultMessage: '刪除' },
  update: { id: 'update', defaultMessage: '更新' },
  login: { id: 'login', defaultMessage: '登入' },
  logout: { id: 'logout', defaultMessage: '登出' },
  submit: { id: 'submit', defaultMessage: '提交' },
  viewUsers: { id: 'viewUsers', defaultMessage: '查看用戶' },
  addUser: { id: 'addUser', defaultMessage: '新增用戶' },
  yes: { id: 'yes', defaultMessage: '是' },
  no: { id: 'no', defaultMessage: '否' },
  deleteReminder: { id: 'deleteReminder', defaultMessage: '確定刪除？' },
  cancelReminder: { id: 'cancelReminder', defaultMessage: '確定取消？' },
  pleaseCheckYourEmail: {
    id: 'pleaseCheckYourEmail',
    defaultMessage: '請查收電郵',
  },
  passwordChangedReminder: {
    id: 'passwordChangedReminder',
    defaultMessage: '密碼已更新，請再次登入。',
  },
  createAdminSuccess: {
    id: 'createAdminSuccess',
    defaultMessage: '創建管理員成功',
  },
  createAdminFailure: {
    id: 'createAdminFailure',
    defaultMessage: '創建管理員失敗',
  },
  updateAdminSuccess: {
    id: 'updateAdminSuccess',
    defaultMessage: '更新管理員成功',
  },
  updateAdminFailure: {
    id: 'updateAdminFailure',
    defaultMessage: '更新管理員失敗',
  },
  pleaseInputUsername: {
    id: 'pleaseInputUsername',
    defaultMessage: '請輸入用戶名',
  },
  pleaseInputName: { id: 'pleaseInputName', defaultMessage: '請輸入名稱' },
  pleaseInputEmail: { id: 'pleaseInputEmail', defaultMessage: '請輸入電郵' },
  pleaseInputPhoneNumber: {
    id: 'pleaseInputPhoneNumber',
    defaultMessage: '請輸入電話號碼',
  },
  pleaseInputPassword: {
    id: 'pleaseInputPassword',
    defaultMessage: '請輸入密碼',
  },
  pleaseInputAddress: {
    id: 'pleaseInputAddress',
    defaultMessage: '請輸入地址',
  },
  pleaseConfirmPassword: {
    id: 'pleaseConfirmPassword',
    defaultMessage: '請確認密碼',
  },
  confirmPasswordError: {
    id: 'confirmPasswordError',
    defaultMessage: '密碼不相符',
  },
  emailFormatError: { id: 'emailFormatError', defaultMessage: '電郵格式錯誤' },
  usernameFormatError: {
    id: 'usernameFormatError',
    defaultMessage: '用戶名格式錯誤',
  },
  nameFormatError: { id: 'nameFormatError', defaultMessage: '名稱格式錯誤' },
  passwordFormatError: {
    id: 'passwordFormatError',
    defaultMessage: '密碼格式錯誤',
  },
  price: { id: 'price', defaultMessage: '價格' },
  reset: { id: 'reset', defaultMessage: '重設' },
  confirm: { id: 'confirm', defaultMessage: '確定' },
  pleaseInput: { id: 'pleaseInput', defaultMessage: '請輸入' },
  pleaseSelect: { id: 'pleaseSelect', defaultMessage: '請選擇' },
  type: { id: 'type', defaultMessage: '類型' },
  subType: { id: 'subType', defaultMessage: '子類型' },
  save: { id: 'save', defaultMessage: '儲存' },
  cancel: { id: 'cancel', defaultMessage: '取消' },
  englishName: { id: 'englishName', defaultMessage: '英文名' },
  sex: { id: 'sex', defaultMessage: '性別' },
  male: { id: 'male', defaultMessage: '男' },
  female: { id: 'female', defaultMessage: '女' },
  startTime: { id: 'startTime', defaultMessage: '開始時間' },
  endTime: { id: 'endTime', defaultMessage: '結束時間' },
  copy: { id: 'copy', defaultMessage: '複制' },
  addNew: { id: 'addNew', defaultMessage: '新增' },
  search: { id: 'search', defaultMessage: '搜尋' },
  read: { id: 'read', defaultMessage: '查閱' },
  content: { id: 'content', defaultMessage: '內容' },
  status: { id: 'status', defaultMessage: '狀態' },
  active: { id: 'active', defaultMessage: '正常' },
  inactive: { id: 'inactive', defaultMessage: '禁用' },
  showTotalDisplayText: {
    id: 'showTotalDisplayText',
    defaultMessage: '共{total}條',
  },
  patient: { id: 'patient', defaultMessage: '病人' },
  firstNameInChinese: { id: 'firstNameInChinese', defaultMessage: '名(中文)' },
  lastNameInChinese: { id: 'lastNameInChinese', defaultMessage: '姓(中文)' },
  firstNameInEnglish: { id: 'firstNameInEnglish', defaultMessage: '名(英文)' },
  lastNameInEnglish: { id: 'lastNameInEnglish', defaultMessage: '姓(英文)' },
  hkid: { id: 'hkid', defaultMessage: '香港身份證號碼' },
  contactNumber: { id: 'contactNumber', defaultMessage: '聯絡電話' },
  patientList: { id: 'patientList', defaultMessage: '病人列表' },
  dateOfBirth: { id: 'dateOfBirth', defaultMessage: '出生日期' },
  allergy: { id: 'allergy', defaultMessage: '過敏' },
  addNewPatient: { id: 'addNewPatient', defaultMessage: '新增病人' },
  resetAllSearch: { id: 'resetAllSearch', defaultMessage: '重設所有搜尋' },
  hkidFormatError: {
    id: 'hkidFormatError',
    defaultMessage: '香港身份證號碼格式錯誤',
  },
  addNewPatientSuccess: {
    id: 'addNewPatientSuccess',
    defaultMessage: '新增病人成功',
  },
  addNewPatientFailure: {
    id: 'addNewPatientFailure',
    defaultMessage: '新增病人失敗',
  },
  editPatient: { id: 'editPatient', defaultMessage: '更改病人' },
  editPatientSuccess: {
    id: 'editPatientSuccess',
    defaultMessage: '更改病人成功',
  },
  editPatientFailure: {
    id: 'editPatientFailure',
    defaultMessage: '更改病人失敗',
  },
  consultation: { id: 'consultation', defaultMessage: '病歷' },
  createConsultation: { id: 'createConsultation', defaultMessage: '新增病歷' },
  createConsultationSuccess: {
    id: 'createConsultationSuccess',
    defaultMessage: '新增病歷成功',
  },
  createConsultationFailure: {
    id: 'createConsultationFailure',
    defaultMessage: '新增病歷失敗',
  },
  editConsultation: { id: 'editConsultation', defaultMessage: '更改病歷' },
  editConsultationSuccess: {
    id: 'editConsultationSuccess',
    defaultMessage: '更改病歷成功',
  },
  editConsultationFailure: {
    id: 'editConsultationFailure',
    defaultMessage: '更改病歷失敗',
  },
  doctor: { id: 'doctor', defaultMessage: '醫生' },
  prescription: { id: 'prescription', defaultMessage: '處方' },
  remark: { id: 'remark', defaultMessage: '備註' },
  queuing: { id: 'queuing', defaultMessage: '排隊中' },
  consulting: { id: 'consulting', defaultMessage: '診症中' },
  medicationDispensing: {
    id: 'medicationDispensing',
    defaultMessage: '配藥中',
  },
  medicationCollecting: {
    id: 'medicationCollecting',
    defaultMessage: '待取藥',
  },
  completed: { id: 'completed', defaultMessage: '已完成' },
  staff: { id: 'staff', defaultMessage: '員工' },
  nurse: { id: 'nurse', defaultMessage: '護士' },
  xercoAdmin: { id: 'xercoAdmin', defaultMessage: 'Xerco管理員' },
  addNewStaff: { id: 'addNewStaff', defaultMessage: '新增員工' },
  addNewStaffSuccess: {
    id: 'addNewStaffSuccess',
    defaultMessage: '新增員工成功',
  },
  addNewStaffFailure: {
    id: 'addNewStaffFailure',
    defaultMessage: '新增員工失敗',
  },
  updateStaff: { id: 'updateStaff', defaultMessage: '更改員工' },
  updateStaffSuccess: {
    id: 'updateStaffSuccess',
    defaultMessage: '更改員工成功',
  },
  updateStaffFailure: {
    id: 'updateStaffFailure',
    defaultMessage: '更改員工失敗',
  },
  home: { id: 'home', defaultMessage: '主頁' },
  consult: { id: 'consult', defaultMessage: '診症' },
  medicationDispense: { id: 'medicationDispense', defaultMessage: '配藥' },
  medicationDispensed: {
    id: 'medicationDispensed',
    defaultMessage: '配藥完成',
  },
  medicationCollected: { id: 'medicationCollected', defaultMessage: '已取藥' },
  checkout: { id: 'checkout', defaultMessage: '付款' },
  paid: { id: 'paid', defaultMessage: '已付款' },
  doctorWithFullName: {
    id: 'doctorWithFullName',
    defaultMessage: '{lastName}{firstName} 醫生',
  },
  reservation: { id: 'reservation', defaultMessage: '預約' },
  reserveTime: { id: 'reserveTime', defaultMessage: '預約時間' },
  createReservation: { id: 'createReservation', defaultMessage: '新增預約' },
  createReservationSuccess: {
    id: 'createReservationSuccess',
    defaultMessage: '新增預約成功',
  },
  createReservationFailure: {
    id: 'createReservationFailure',
    defaultMessage: '新增預約失敗',
  },
  updateReservation: { id: 'updateReservation', defaultMessage: '更改預約' },
  updateReservationSuccess: {
    id: 'updateReservationSuccess',
    defaultMessage: '更改預約成功',
  },
  updateReservationFailure: {
    id: 'updateReservationFailure',
    defaultMessage: '更改預約失敗',
  },
  pending: { id: 'pending', defaultMessage: '等待中' },
  reservationDetail: { id: 'reservationDetail', defaultMessage: '預約詳情' },
  drug: { id: 'drug', defaultMessage: '藥物' },
  hkregno: { id: 'hkregno', defaultMessage: '香港註冊編號' },
  drugName: { id: 'drugName', defaultMessage: '藥物名稱' },
  addClinicDrug: { id: 'addClinicDrug', defaultMessage: '新增診所藥物' },
  addClinicDrugSuccess: {
    id: 'addClinicDrugSuccess',
    defaultMessage: '新增診所藥物成功',
  },
  addClinicDrugFailure: {
    id: 'addClinicDrugFailure',
    defaultMessage: '新增診所藥物失敗',
  },
  deleteClinicDrug: { id: 'deleteClinicDrug', defaultMessage: '刪除診所藥物' },
  deleteClinicDrugSuccess: {
    id: 'deleteClinicDrugSuccess',
    defaultMessage: '刪除診所藥物成功',
  },
  deleteClinicDrugFailure: {
    id: 'deleteClinicDrugFailure',
    defaultMessage: '刪除診所藥物失敗',
  },
  rankDrug: { id: 'rankDrug', defaultMessage: '第{rank}項藥物' },
  pleaseInputToSearch: {
    id: 'pleaseInputToSearch',
    defaultMessage: '輸入關鍵字搜尋',
  },
  noteTemplate: { id: 'noteTemplate', defaultMessage: '病歷書範例' },
  isPrivateUsage: { id: 'isPrivateUsage', defaultMessage: '是否私用' },
  createNoteTemplateSuccess: {
    id: 'createNoteTemplateSuccess',
    defaultMessage: '新增病歷書範例成功',
  },
  createNoteTemplateFailure: {
    id: 'createNoteTemplateFailure',
    defaultMessage: '新增病歷書範例失敗',
  },
  updateNoteTemplateSuccess: {
    id: 'updateNoteTemplateSuccess',
    defaultMessage: '更改病歷書範例成功',
  },
  updateNoteTemplateFailure: {
    id: 'updateNoteTemplateFailure',
    defaultMessage: '更改病歷書範例失敗',
  },
  patientInformation: { id: 'patientInformation', defaultMessage: '病人資料' },
  reserved: { id: 'reserved', defaultMessage: '已預約' },
  queue: { id: 'queue', defaultMessage: '排隊' },
  productName: { id: 'productName', defaultMessage: '產品名稱' },
  completeConsultation: {
    id: 'completeConsultation',
    defaultMessage: '完成診症',
  },
  pastConsultation: { id: 'pastConsultation', defaultMessage: '過去病歷' },
  view: { id: 'view', defaultMessage: '查看' },
  success: { id: 'success', defaultMessage: '成功' },
  failure: { id: 'failure', defaultMessage: '失敗' },
  patientHasIncompletedConsultationToday: {
    id: 'patientHasIncompletedConsultationToday',
    defaultMessage: '病人今天尚有未完成診症',
  },
  wrongPassword: { id: 'wrongPassword', defaultMessage: '密碼錯誤' },
  doctorStillInConsulting: {
    id: 'doctorStillInConsulting',
    defaultMessage: '醫生診症中',
  },
  searchDrug: { id: 'searchDrug', defaultMessage: '搜尋藥物' },
  addDrug: { id: 'addDrug', defaultMessage: '新增藥物' },
  customDrug: { id: 'customDrug', defaultMessage: '自訂藥物' },
  clinicConsultation: { id: 'clinicConsultation', defaultMessage: '診所病歷' },
  backToHome: { id: 'backToHome', defaultMessage: '返回主頁' },
  searchPatient: { id: 'searchPatient', defaultMessage: '搜尋病人' },
  patientNamePhoneNumberHKID: {
    id: 'patientNamePhoneNumberHKID',
    defaultMessage: '病人名稱/電話/身份證',
  },
  existingPatient: { id: 'existingPatient', defaultMessage: '現有病人' },
  newPatient: { id: 'newPatient', defaultMessage: '新病人' },
  makeAppointment: { id: 'makeAppointment', defaultMessage: '新增預約' },
  makeAppointmentSuccess: {
    id: 'makeAppointmentSuccess',
    defaultMessage: '新增預約成功',
  },
  makeAppointmentFailure: {
    id: 'makeAppointmentFailure',
    defaultMessage: '新增預約失敗',
  },
  editAppointment: { id: 'editAppointment', defaultMessage: '更改預約' },
  editAppointmentSuccess: {
    id: 'editAppointmentSuccess',
    defaultMessage: '更改預約成功',
  },
  editAppointmentFailure: {
    id: 'editAppointmentFailure',
    defaultMessage: '更改預約失敗',
  },
  clinicRecord: { id: 'clinicRecord', defaultMessage: '診所記錄' },
  drugHasBeenAddedAlready: {
    id: 'drugHasBeenAddedAlready',
    defaultMessage: '藥物已存在',
  },
  selectToViewPastConsultation: {
    id: 'selectToViewPastConsultation',
    defaultMessage: '選擇過去病歷',
  },
  updatePatientAllergy: {
    id: 'updatePatientAllergy',
    defaultMessage: '更新病人過敏',
  },
  immediate: { id: 'immediate', defaultMessage: '立刻' },
  updateStock: { id: 'updateStock', defaultMessage: '更新存貨' },
  stock: { id: 'stock', defaultMessage: '存貨' },
  unit: { id: 'unit', defaultMessage: '單位' },
  overrideReason: { id: 'overrideReason', defaultMessage: '無視警告原因' },
  warning: { id: 'warning', defaultMessage: '警告' },
  referralLetter: { id: 'referralLetter', defaultMessage: '轉介信' },
  sickLeaveLetter: { id: 'sickLeaveLetter', defaultMessage: '病假紙' },
  print: { id: 'print', defaultMessage: '列印' },
  date: { id: 'date', defaultMessage: '日期' },
  sickLeavePeriod: { id: 'sickLeavePeriod', defaultMessage: '病假日期' },
  startDate: { id: 'startDate', defaultMessage: '開始日期' },
  endDate: { id: 'endDate', defaultMessage: '結束日期' },
  diagnosis: { id: 'diagnosis', defaultMessage: '診斷' },
  pleaseClinicCode: {
    id: 'pleaseClinicCode',
    defaultMessage: '請輸入診所編號',
  },
  clinicCode: { id: 'clinicCode', defaultMessage: '診所編號' },
  clinicNameInEnglish: {
    id: 'clinicNameInEnglish',
    defaultMessage: '診所名稱(英文)',
  },
  clinicNameInChinese: {
    id: 'clinicNameInChinese',
    defaultMessage: '診所名稱(中文)',
  },
  addressInEnglish: { id: 'addressInEnglish', defaultMessage: '地址(英文)' },
  addressInChinese: { id: 'addressInChinese', defaultMessage: '地址(中文)' },
  createClinic: { id: 'createClinic', defaultMessage: '新增診所' },
  createClinicSuccess: {
    id: 'createClinicSuccess',
    defaultMessage: '新增診所',
  },
  createClinicFailure: {
    id: 'createClinicFailure',
    defaultMessage: '新增診所',
  },
  updateClinic: { id: 'updateClinic', defaultMessage: '更改診所' },
  updateClinicSuccess: {
    id: 'updateClinicSuccess',
    defaultMessage: '更改診所',
  },
  updateClinicFailure: {
    id: 'updateClinicFailure',
    defaultMessage: '更改診所',
  },
  clinicManagement: { id: 'clinicManagement', defaultMessage: '診所管理' },
  updateAdmin: { id: 'updateAdmin', defaultMessage: '更新管理員' },
  clinic: { id: 'clinic', defaultMessage: '診所' },
  selectClinic: { id: 'selectClinic', defaultMessage: '選擇診所' },
  staffManagement: { id: 'staffManagement', defaultMessage: '員工管理' },
  selectedClinic: { id: 'selectedClinic', defaultMessage: '已選擇診所' },
  otherClinic: { id: 'otherClinic', defaultMessage: '其他診所' },
  subscriptionPlan: { id: 'subscriptionPlan', defaultMessage: '訂閱計劃' },
  createSubscriptionPlan: {
    id: 'createSubscriptionPlan',
    defaultMessage: '新增訂閱計劃',
  },
  monthlyPrice: { id: 'monthlyPrice', defaultMessage: '月費' },
  stripePriceId: { id: 'stripePriceId', defaultMessage: 'Stripe Price ID' },
  doctorCount: { id: 'doctorCount', defaultMessage: '醫生數目' },
  error: { id: 'error', defaultMessage: '錯誤' },
  letterTemplate: { id: 'letterTemplate', defaultMessage: '書信範例' },
  createLetterTemplateSuccess: {
    id: 'createLetterTemplateSuccess',
    defaultMessage: '新增書信範例成功',
  },
  createLetterTemplateFailure: {
    id: 'createLetterTemplateFailure',
    defaultMessage: '新增書信範例失敗',
  },
  updateLetterTemplateSuccess: {
    id: 'updateLetterTemplateSuccess',
    defaultMessage: '更改書信範例成功',
  },
  updateLetterTemplateFailure: {
    id: 'updateLetterTemplateFailure',
    defaultMessage: '更改書信範例失敗',
  },
  deleteLetterTemplateSuccess: {
    id: 'deleteLetterTemplateSuccess',
    defaultMessage: '刪除書信範例成功',
  },
  deleteLetterTemplateFailure: {
    id: 'deleteLetterTemplateFailure',
    defaultMessage: '刪除書信範例失敗',
  },
  selectToViewLetterTemplate: {
    id: 'selectToViewLetterTemplate',
    defaultMessage: '選擇書信範例',
  },
  preview: { id: 'preview', defaultMessage: '預覽' },
  pageNotFound: { id: 'pageNotFound', defaultMessage: '找不到頁面' },
  rememberMe: { id: 'rememberMe', defaultMessage: '記住我' },
  priority: { id: 'priority', defaultMessage: '優先次序' },
  title: { id: 'title', defaultMessage: '標題' },
  faq: { id: 'faq', defaultMessage: 'FAQ' },
  tutorial: { id: 'tutorial', defaultMessage: '教學' },
  tnc: { id: 'tnc', defaultMessage: 'T&C' },
  subscribing: { id: 'subscribing', defaultMessage: '訂閱中' },
  onTrial: { id: 'onTrial', defaultMessage: '正在試用' },
  expired: { id: 'expired', defaultMessage: '已過期' },
  quit: { id: 'quit', defaultMessage: '已退出' },
  back: { id: 'back', defaultMessage: '返回' },
  district: { id: 'district', defaultMessage: '地區' },
  businessRegistrationNumber: {
    id: 'businessRegistrationNumber',
    defaultMessage: '商業登記號碼',
  },
  frozen: { id: 'frozen', defaultMessage: '已凍結' },
  log: { id: 'log', defaultMessage: '日誌' },
  settings: { id: 'settings', defaultMessage: '設定' },
})
