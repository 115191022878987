import React from 'react'
import styled from 'styled-components'
import DelayedSpin from '~/src/components/delayedSpin'

const LoadingWrapper = styled.div`
  width: 100%;
  height: ${(props) => (props.isFullPage ? '100vh' : '100%')};
`

const LoadingContent = styled.div`
  height: ${(props) => (props.isFullPage ? '100vh' : '100%')};
  margin: 0 auto;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
`

const Loading = ({ isFullPage }) => {
  return (
    <LoadingWrapper isFullPage={isFullPage}>
      <LoadingContent isFullPage={isFullPage}>
        <DelayedSpin />
      </LoadingContent>
    </LoadingWrapper>
  )
}

export default Loading
