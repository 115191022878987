import { action, flow, makeAutoObservable, observable } from 'mobx'
import api from '~/src/api'

class ClinicStore {
  isSubmitting = false
  error = null

  constructor(rootStore) {
    this.rootStore = rootStore
    makeAutoObservable(this, {
      isSubmitting: observable,
      error: observable,
      reset: action,
      getClinic: flow,
      listClinic: flow,
      updateClinicStatus: flow,
      updateClinicTags: flow,
      updateClinicFreeForever: flow,
      listClinicDocument: flow,
      getClinicDocumentLink: flow,
    })
  }

  reset() {
    this.isSubmitting = false
    this.error = null
  }

  *getClinic(clinicId) {
    this.isSubmitting = true
    const { commonStore } = this.rootStore
    const { token } = commonStore
    try {
      const response = yield api.getClinic(token, clinicId)
      this.error = null
      this.isSubmitting = false
      return Promise.resolve(response.data)
    } catch (error) {
      console.log(error)
      this.isSubmitting = false
      this.error = {
        errorCodes: error.response.data.errorCodes,
        messages: error.response.data.messages,
      }
      return Promise.reject()
    }
  }

  *listClinic(limit, skip, tag) {
    this.isSubmitting = true
    const { commonStore } = this.rootStore
    const { token } = commonStore
    try {
      const response = yield api.listClinic(token, limit, skip, tag)
      this.isSubmitting = false
      this.error = null
      return Promise.resolve(response.data)
    } catch (error) {
      console.log(error)
      this.isSubmitting = false
      this.error = {
        errorCodes: error.response.data.errorCodes,
        messages: error.response.data.messages,
      }
      return Promise.reject()
    }
  }

  *updateClinicStatus(clinicId, status) {
    this.isSubmitting = true
    const { commonStore } = this.rootStore
    const { token } = commonStore
    try {
      yield api.updateClinicStatus(token, clinicId, status)
      this.error = null
    } catch (error) {
      console.log(error)
      this.error = {
        errorCodes: error.response.data.errorCodes,
        messages: error.response.data.messages,
      }
    }
    this.isSubmitting = false
  }

  *updateClinicTags(clinicId, tags) {
    this.isSubmitting = true
    const { commonStore } = this.rootStore
    const { token } = commonStore
    try {
      yield api.updateClinicTags(token, clinicId, tags)
      this.error = null
    } catch (error) {
      console.log(error)
      this.error = {
        errorCodes: error.response.data.errorCodes,
        messages: error.response.data.messages,
      }
    }
    this.isSubmitting = false
  }

  *updateClinicFreeForever(clinicId, free) {
    this.isSubmitting = true
    const { commonStore } = this.rootStore
    const { token } = commonStore
    try {
      yield api.updateClinicFreeForever(token, clinicId, free)
      this.error = null
    } catch (error) {
      console.log(error)
      this.error = {
        errorCodes: error.response.data.errorCodes,
        messages: error.response.data.messages,
      }
    }
    this.isSubmitting = false
  }

  *listClinicDocument(clinicId, limit, skip) {
    const { commonStore } = this.rootStore
    const { token } = commonStore
    try {
      const response = yield api.listClinicDocument(
        token,
        clinicId,
        limit,
        skip,
      )
      this.error = null
      return Promise.resolve(response.data)
    } catch (error) {
      console.log(error)
      this.error = {
        errorCodes: error.response.data.errorCodes,
        messages: error.response.data.messages,
      }
      throw Promise.reject(error)
    }
  }

  *getClinicDocumentLink(clinicId, documentId) {
    const { commonStore } = this.rootStore
    const { token } = commonStore
    try {
      const response = yield api.getClinicDocumentLink(
        token,
        clinicId,
        documentId,
      )
      this.error = null
      return Promise.resolve(response.data)
    } catch (error) {
      console.log(error)
      this.error = {
        errorCodes: error.response.data.errorCodes,
        messages: error.response.data.messages,
      }
      throw Promise.reject(error)
    }
  }
}

export default ClinicStore
