import { action, flow, makeAutoObservable, observable } from 'mobx'
import api from '~/src/api'

class FaqStore {
  isSubmitting = false
  error = null

  constructor(rootStore) {
    this.rootStore = rootStore
    makeAutoObservable(this, {
      isSubmitting: observable,
      error: observable,
      reset: action,
      createFaq: flow,
      listFaq: flow,
      updateFaq: flow,
      removeFaq: flow,
    })
  }

  reset() {
    this.isSubmitting = false
    this.error = null
  }

  *createFaq(locale, title, content, priority, status) {
    this.isSubmitting = true
    const { commonStore } = this.rootStore
    const { token } = commonStore
    try {
      yield api.createFaq(token, locale, title, content, priority, status)
      this.error = null
    } catch (error) {
      console.log(error)
      this.error = {
        errorCodes: error.response.data.errorCodes,
        messages: error.response.data.messages,
      }
    }
    this.isSubmitting = false
  }

  *listFaq(limit, skip) {
    this.isSubmitting = true
    const { commonStore } = this.rootStore
    const { token } = commonStore
    try {
      const response = yield api.listFaq(token, limit, skip)
      this.error = null
      this.isSubmitting = false
      return Promise.resolve(response.data)
    } catch (error) {
      console.log(error)
      this.isSubmitting = false
      this.error = {
        errorCodes: error.response.data.errorCodes,
        messages: error.response.data.messages,
      }
      return Promise.reject()
    }
  }

  *updateFaq(faqId, locale, title, content, priority, status) {
    this.isSubmitting = true
    const { commonStore } = this.rootStore
    const { token } = commonStore
    try {
      yield api.updateFaq(
        token,
        faqId,
        locale,
        title,
        content,
        priority,
        status,
      )
      this.error = null
    } catch (error) {
      console.log(error)
      this.error = {
        errorCodes: error.response.data.errorCodes,
        messages: error.response.data.messages,
      }
    }
    this.isSubmitting = false
  }

  *removeFaq(faqId) {
    this.isSubmitting = true
    const { commonStore } = this.rootStore
    const { token } = commonStore
    try {
      yield api.removeFaq(token, faqId)
      this.error = null
    } catch (error) {
      console.log(error)
      this.error = {
        errorCodes: error.response.data.errorCodes,
        messages: error.response.data.messages,
      }
    }
    this.isSubmitting = false
  }
}

export default FaqStore
