import { Drawer, Layout } from 'antd'
import React, { useState } from 'react'
import MediaQuery from 'react-responsive'
import styled from 'styled-components'
import AppBar from '~/src/components/appBar'
import Footer from '~/src/components/footer'
import SiderMenu from '~/src/components/siderMenu'

const TitleWrapper = styled.div`
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  height: 64px;
`

const PageLayout = ({ children }) => {
  const [collapsed, setCollapsed] = useState(false)
  const [drawerOpen, setDrawerOpen] = useState(false)

  const childrenWithProps = React.Children.map(children, (child) =>
    React.cloneElement(child),
  )

  return (
    <Layout style={{ minHeight: '100vh' }}>
      <Layout.Header
        style={{
          padding: 0,
          height: 64,
          position: 'fixed',
          zIndex: 1,
          width: '100%',
        }}
      >
        <AppBar onMenuButtonClick={() => setDrawerOpen(true)} />
      </Layout.Header>
      <MediaQuery minWidth={768}>
        <Layout.Sider
          style={{
            overflow: 'auto',
            height: '100vh',
            position: 'sticky',
            top: 0,
            left: 0,
          }}
          breakpoint="xl"
          collapsible
          collapsed={collapsed}
          onBreakpoint={(broken) => {
            setCollapsed(broken)
          }}
          onCollapse={(collapsed) => {
            setCollapsed(collapsed)
          }}
        >
          <div
            style={{
              marginTop: 64,
            }}
          >
            <TitleWrapper></TitleWrapper>
            <SiderMenu />
          </div>
        </Layout.Sider>
      </MediaQuery>
      <MediaQuery maxWidth={767}>
        <Drawer
          title={null}
          placement="left"
          visible={drawerOpen}
          onClose={() => setDrawerOpen(false)}
          width={256}
          drawerStyle={{
            backgroundColor: '#001529',
          }}
          bodyStyle={{
            padding: 0,
          }}
        >
          <SiderMenu onMenuItemClick={() => setDrawerOpen(false)} />
        </Drawer>
      </MediaQuery>
      <Layout style={{ marginTop: 64 }}>
        <Layout.Content style={{ padding: 16, backgroundColor: 'white' }}>
          {childrenWithProps}
        </Layout.Content>
        <Layout.Footer style={{ padding: 0 }}>
          <Footer />
        </Layout.Footer>
      </Layout>
    </Layout>
  )
}

export default PageLayout
