import { ConfigProvider } from 'antd'
import en_US from 'antd/lib/locale-provider/en_US'
import zh_TW from 'antd/lib/locale-provider/zh_TW'
import { observer } from 'mobx-react'
import moment from 'moment'
import React, { Suspense, lazy, useEffect } from 'react'
import { HelmetProvider } from 'react-helmet-async'
import { IntlProvider } from 'react-intl'
import { BrowserRouter, Route, Switch } from 'react-router-dom'
import { ThemeProvider } from 'styled-components'
import enMessages from '~/src/compiled-lang/en.json'
import zhMessages from '~/src/compiled-lang/zh.json'
import LoadingPage from '~/src/components/loading'
import PrivateRoute from '~/src/components/routes/privateRoute'
import { useStores } from '~/src/hooks'
import { isTokenExpired } from '~/src/lib/tokenHelper'

import './styles/app.less'

moment.locale('zh-hk')

const theme = {
  primary: '#39A5D9',
}

if (!Intl.PluralRules) {
  require('@formatjs/intl-pluralrules/polyfill')
  require('@formatjs/intl-pluralrules/dist/locale-data/zh') // Add locale data for zh
}

if (!Intl.RelativeTimeFormat) {
  require('@formatjs/intl-relativetimeformat/polyfill')
  require('@formatjs/intl-relativetimeformat/dist/locale-data/zh') // Add locale data for zh
}

const AsyncAdminListPage = lazy(() => import('~/src/containers/AdminListPage'))
const AsyncClinicDetailPage = lazy(() =>
  import('~/src/containers/ClinicDetailPage'),
)
const AsyncClinicListPage = lazy(() =>
  import('~/src/containers/ClinicListPage'),
)
const AsyncFaqPage = lazy(() => import('~/src/containers/FaqPage'))
const AsyncForgotPasswordPage = lazy(() =>
  import('~/src/containers/ForgotPasswordPage'),
)
const AsyncLetterTemplatePage = lazy(() =>
  import('~/src/containers/LetterTemplatePage'),
)
const AsyncLoginPage = lazy(() => import('~/src/containers/LoginPage'))
const AsyncMainPage = lazy(() => import('~/src/containers/MainPage'))
const AsyncNotFoundPage = lazy(() => import('~/src/containers/NotFoundPage'))
const AsyncResetPasswordPage = lazy(() =>
  import('~/src/containers/ResetPasswordPage'),
)
const AsyncSubscriptionPlanPage = lazy(() =>
  import('~/src/containers/SubscriptionPlanPage'),
)
const AsyncTncPage = lazy(() => import('~/src/containers/TncPage'))
const AsyncTutorialPage = lazy(() => import('~/src/containers/TutorialPage'))
const AsyncUserPage = lazy(() => import('~/src/containers/UserPage'))

const App = () => {
  const { authStore, commonStore } = useStores()
  useEffect(() => {
    const getInitialData = async () => {
      if (
        commonStore.token != null &&
        !isTokenExpired(commonStore.tokenExpAt)
      ) {
        await Promise.all([authStore.getSelf()])
      }
    }
    getInitialData()
    window.addEventListener('storage', (e) => {
      if (e.key === 'logout') {
        authStore.logout()
        window.location.reload()
      }
    })
  }, [authStore, commonStore])

  const messages = commonStore.locale === 'en' ? enMessages : zhMessages

  return (
    <HelmetProvider>
      <ThemeProvider theme={theme}>
        <IntlProvider locale={commonStore.locale} messages={messages}>
          <ConfigProvider locale={commonStore.locale === 'zh' ? zh_TW : en_US}>
            <BrowserRouter>
              <Suspense fallback={<LoadingPage isFullPage />}>
                <Switch>
                  <Route exact path="/login" component={AsyncLoginPage} />
                  <Route
                    exact
                    path="/forgotPassword"
                    component={AsyncForgotPasswordPage}
                  />
                  <Route
                    exact
                    path="/resetPassword"
                    component={AsyncResetPasswordPage}
                  />
                  <PrivateRoute exact path="/" component={AsyncMainPage} />
                  <PrivateRoute
                    exact
                    path="/clinics/:clinicId"
                    component={AsyncClinicDetailPage}
                  />
                  <PrivateRoute
                    exact
                    path="/admins"
                    component={AsyncAdminListPage}
                  />
                  <PrivateRoute
                    exact
                    path="/clinics"
                    component={AsyncClinicListPage}
                  />
                  <PrivateRoute exact path="/users" component={AsyncUserPage} />
                  <PrivateRoute
                    exact
                    path="/subscriptionPlan"
                    component={AsyncSubscriptionPlanPage}
                  />
                  <PrivateRoute
                    exact
                    path="/letterTemplate"
                    component={AsyncLetterTemplatePage}
                  />
                  <PrivateRoute exact path="/faq" component={AsyncFaqPage} />
                  <PrivateRoute
                    exact
                    path="/tutorial"
                    component={AsyncTutorialPage}
                  />
                  <PrivateRoute exact path="/tnc" component={AsyncTncPage} />
                  <Route path="*" component={AsyncNotFoundPage} />
                </Switch>
              </Suspense>
            </BrowserRouter>
          </ConfigProvider>
        </IntlProvider>
      </ThemeProvider>
    </HelmetProvider>
  )
}

export default observer(App)
