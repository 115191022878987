import AuthStore from './authStore'
import ClinicStore from './clinicStore'
import CommonStore from './commonStore'
import FaqStore from './faqStore'
import LetterTemplateStore from './letterTemplateStore'
import SubscriptionPlanStore from './subscriptionPlanStore'
import SubscriptionStore from './subscriptionStore'
import TncStore from './tncStore'
import TutorialStore from './tutorialStore'
import UserStore from './userStore'

class RootStore {
  constructor(rootStore) {
    this.rootStore = rootStore
    this.authStore = new AuthStore(this)
    this.clinicStore = new ClinicStore(this)
    this.commonStore = new CommonStore(this)
    this.faqStore = new FaqStore(this)
    this.letterTemplateStore = new LetterTemplateStore(this)
    this.subscriptionPlanStore = new SubscriptionPlanStore(this)
    this.subscriptionStore = new SubscriptionStore(this)
    this.tncStore = new TncStore(this)
    this.tutorialStore = new TutorialStore(this)
    this.userStore = new UserStore(this)
  }
}

export default RootStore
