import React, { Suspense } from 'react'
import { Redirect, Route } from 'react-router-dom'
import PageLayout from '~/src/components/layouts/main'
import Loading from '~/src/components/loading'
import { useStores } from '~/src/hooks'
import { isTokenExpired } from '~/src/lib/tokenHelper'

function loggedIn(token, tokenExpAt) {
  return token && !isTokenExpired(tokenExpAt)
}

export default function PrivateRoute({ component: Component, path, ...rest }) {
  const { commonStore } = useStores()
  return (
    <Route
      path={path}
      {...rest}
      render={(props) => {
        if (loggedIn(commonStore.token, commonStore.tokenExpAt)) {
          return (
            <PageLayout>
              <Suspense fallback={<Loading />}>
                <Component {...props} />
              </Suspense>
            </PageLayout>
          )
        } else {
          return (
            <Redirect
              to={{
                pathname: '/login',
                state: { from: props.location },
              }}
            />
          )
        }
      }}
    />
  )
}
