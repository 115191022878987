import { Menu } from 'antd'
import React, { useMemo } from 'react'
import { useIntl } from 'react-intl'
import { Link, useLocation } from 'react-router-dom'
import messages from '~/src/messages'

const siderItems = [
  {
    key: 'home',
    value: 'home',
    text: messages.home,
    icon: 'home',
    route: '/',
  },
  {
    key: 'admins',
    value: 'admins',
    text: messages.adminList,
    icon: 'idcard',
    route: '/admins',
  },
  {
    key: 'clinics',
    value: 'clinics',
    text: messages.clinicManagement,
    icon: 'medicine-box',
    route: '/clinics',
  },
  {
    key: 'users',
    value: 'users',
    text: messages.user,
    icon: 'users',
    route: '/users',
  },
  {
    key: 'subscriptionPlan',
    value: 'subscriptionPlan',
    text: messages.subscriptionPlan,
    icon: 'medicine-box',
    route: '/subscriptionPlan',
  },
  {
    key: 'letterTemplate',
    value: 'letterTemplate',
    text: messages.letterTemplate,
    icon: 'snippets',
    route: '/letterTemplate',
  },
  {
    key: 'faq',
    value: 'faq',
    text: messages.faq,
    icon: 'snippets',
    route: '/faq',
  },
  {
    key: 'tutorial',
    value: 'tutorial',
    text: messages.tutorial,
    icon: 'snippets',
    route: '/tutorial',
  },
  {
    key: 'tnc',
    value: 'tnc',
    text: messages.tnc,
    icon: 'snippets',
    route: '/tnc',
  },
]

const SiderMenu = ({ onMenuItemClick }) => {
  const intl = useIntl()
  const location = useLocation()
  const { locale } = intl

  const menuItems = useMemo(() => {
    return siderItems.map((item) => {
      return (
        <Menu.Item key={item.key} route={item.route}>
          <Link key={item.key} to={`${item.route}?locale=${locale}`}>
            {intl.formatMessage(item.text)}
          </Link>
        </Menu.Item>
      )
    })
  }, [intl, locale])

  const pathArray = location.pathname.substring(1).split('/')
  var selectedKey = pathArray[0] || 'home'
  return (
    <Menu
      theme="dark"
      style={{ maxWidth: 256 }}
      onClick={() => (onMenuItemClick ? onMenuItemClick() : undefined)}
      selectedKeys={[selectedKey]}
      mode="inline"
    >
      {menuItems}
    </Menu>
  )
}

export default SiderMenu
