import { action, flow, makeAutoObservable, observable } from 'mobx'
import api from '~/src/api'

class AuthStore {
  isSubmitting = false
  error = null
  isResetEmailSent = false

  constructor(rootStore) {
    this.rootStore = rootStore
    makeAutoObservable(this, {
      isSubmitting: observable,
      error: observable,
      isResetEmailSent: observable,
      login: flow,
      forgotPassword: flow,
      resetPassword: flow,
      logout: action,
      getSelf: flow,
    })
  }

  *login(_email, _password, rememberMe) {
    this.isSubmitting = true
    const { commonStore } = this.rootStore
    try {
      const response = yield api.login(_email, _password)
      commonStore.setUser(response.data, rememberMe)
      this.error = null
      this.isSubmitting = false
      return Promise.resolve()
    } catch (error) {
      this.error = {
        errorCodes: error.response.data.errorCodes,
        messages: error.response.data.messages,
      }
      this.isSubmitting = false
      return Promise.reject()
    }
  }

  *forgotPassword(email) {
    this.isSubmitting = true
    try {
      yield api.forgotPassword(email)
      this.error = null
      this.isSubmitting = false
      return Promise.resolve()
    } catch (error) {
      console.log(error)
      this.error = {
        errorCodes: error.response.data.errorCodes,
        messages: error.response.data.messages,
      }
      this.isSubmitting = false
      return Promise.reject()
    }
  }

  *resetPassword(token, password) {
    this.isSubmitting = true
    try {
      yield api.resetPassword(token, password)
      this.error = null
      this.isSubmitting = false
      return Promise.resolve()
    } catch (error) {
      console.log(error)
      this.error = {
        errorCodes: error.response.data.errorCodes,
        messages: error.response.data.messages,
      }
      this.isSubmitting = false
      return Promise.reject()
    }
  }

  logout() {
    this.rootStore.commonStore.removeUser()
    this.rootStore.clinicStore.reset()
    this.rootStore.faqStore.reset()
    this.rootStore.letterTemplateStore.reset()
    this.rootStore.subscriptionPlanStore.reset()
    this.rootStore.tncStore.reset()
    this.rootStore.tutorialStore.reset()
    this.rootStore.userStore.reset()
    localStorage.setItem('logout', Date.now())
    return Promise.resolve()
  }

  *getSelf() {
    this.isSubmitting = true
    try {
      const { commonStore } = this.rootStore
      const { token } = commonStore
      const response = yield api.getSelf(token)
      commonStore.updateUser(response.data)
      this.error = null
    } catch (error) {
      console.log(error)
      this.error = {
        errorCodes: error.response.data.errorCodes,
        messages: error.response.data.messages,
      }
    }
    this.isSubmitting = false
  }
}

export default AuthStore
