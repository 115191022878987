import { Spin } from 'antd'
import React, { useEffect, useState } from 'react'

const DelayedSpinner = () => {
  const [showSpinner, setShowSpinner] = useState(false)

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowSpinner(true)
    }, 750)

    return () => clearTimeout(timer)
  })

  return showSpinner && <Spin size="large" />
}

export default DelayedSpinner
