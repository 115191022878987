import axios from 'axios'

const BASE_URL = process.env.REACT_APP_BASE_URL

async function login(email, password) {
  const options = {
    method: 'post',
    url: BASE_URL + '/admin/login',
    data: {
      email,
      password,
    },
  }
  return axios(options)
}

async function forgotPassword(email) {
  const options = {
    method: 'post',
    url: BASE_URL + '/admin/forgotPassword',
    data: {
      email,
    },
  }
  return axios(options)
}

async function resetPassword(token, password) {
  const options = {
    method: 'put',
    url: BASE_URL + '/admin/resetPassword',
    headers: {
      Authorization: `JWT ${token}`,
    },
    data: {
      password,
    },
  }
  return axios(options)
}

async function getSelf(token) {
  const options = {
    method: 'get',
    url: `${BASE_URL}/user/self`,
    headers: {
      Authorization: `JWT ${token}`,
    },
  }
  return axios(options)
}

async function listAdmin(
  token,
  limit,
  skip,
  sort,
  username,
  firstNameZh,
  lastNameZh,
  firstNameEn,
  lastNameEn,
  email,
  status,
  createdAtMin,
  createdAtMax,
) {
  const options = {
    method: 'get',
    url: `${BASE_URL}/admin`,
    headers: {
      Authorization: `JWT ${token}`,
    },
    params: {
      limit,
      skip,
      sort,
      username,
      firstNameZh,
      lastNameZh,
      firstNameEn,
      lastNameEn,
      email,
      status,
      createdAtMin,
      createdAtMax,
    },
  }
  return axios(options)
}

async function createAdmin(
  token,
  username,
  firstNameZh,
  lastNameZh,
  firstNameEn,
  lastNameEn,
  email,
  password,
  status,
) {
  const options = {
    method: 'post',
    url: `${BASE_URL}/admin`,
    headers: {
      Authorization: `JWT ${token}`,
    },
    data: {
      username,
      firstNameZh,
      lastNameZh,
      firstNameEn,
      lastNameEn,
      email,
      password,
      status,
    },
  }
  return axios(options)
}

async function updateAdmin(
  token,
  userId,
  username,
  firstNameZh,
  lastNameZh,
  firstNameEn,
  lastNameEn,
  email,
  status,
) {
  const options = {
    method: 'put',
    url: `${BASE_URL}/admin/${userId}`,
    headers: {
      Authorization: `JWT ${token}`,
    },
    data: {
      username,
      firstNameZh,
      lastNameZh,
      firstNameEn,
      lastNameEn,
      email,
      status,
    },
  }
  return axios(options)
}

async function listClinic(token, limit, skip, tag) {
  const options = {
    method: 'get',
    url: `${BASE_URL}/admin/clinic`,
    headers: {
      Authorization: `JWT ${token}`,
    },
    params: {
      limit,
      skip,
      tag,
    },
  }
  return axios(options)
}

async function listClinicUser(token, clinicId) {
  const options = {
    method: 'get',
    url: `${BASE_URL}/admin/clinic/${clinicId}/user`,
    headers: {
      Authorization: `JWT ${token}`,
    },
  }
  return axios(options)
}

async function createClinicUser(
  token,
  clinicId,
  username,
  firstNameZh,
  lastNameZh,
  firstNameEn,
  lastNameEn,
  email,
  password,
  role,
) {
  const options = {
    method: 'post',
    url: `${BASE_URL}/clinic/${clinicId}/user`,
    headers: {
      Authorization: `JWT ${token}`,
    },
    data: {
      username,
      firstNameZh,
      lastNameZh,
      firstNameEn,
      lastNameEn,
      email,
      password,
      role,
    },
  }
  return axios(options)
}

async function updateClinicUser(
  token,
  clinicId,
  staffId,
  username,
  firstNameZh,
  lastNameZh,
  firstNameEn,
  lastNameEn,
  email,
  role,
) {
  const options = {
    method: 'put',
    url: `${BASE_URL}/clinic/${clinicId}/user/${staffId}`,
    headers: {
      Authorization: `JWT ${token}`,
    },
    data: {
      username,
      firstNameZh,
      lastNameZh,
      firstNameEn,
      lastNameEn,
      email,
      role,
    },
  }
  return axios(options)
}

async function searchClinic(token, query) {
  const options = {
    method: 'get',
    url: `${BASE_URL}/admin/clinic/search`,
    headers: {
      Authorization: `JWT ${token}`,
    },
    params: {
      query,
    },
  }
  return axios(options)
}

async function getClinic(token, clinicId) {
  const options = {
    method: 'get',
    url: `${BASE_URL}/admin/clinic/${clinicId}`,
    headers: {
      Authorization: `JWT ${token}`,
    },
  }
  return axios(options)
}

async function listSubscriptionPlan(token) {
  const options = {
    method: 'get',
    url: `${BASE_URL}/admin/subscriptionPlan`,
    headers: {
      Authorization: `JWT ${token}`,
    },
  }
  return axios(options)
}

async function checkHealth(token, url) {
  const options = {
    method: 'get',
    url: `${url}/admin/health`,
    headers: {
      Authorization: `JWT ${token}`,
    },
  }
  return axios(options)
}

async function checkOrenServerHealth(token) {
  const options = {
    method: 'get',
    url: `${BASE_URL}/admin/oren/health`,
    headers: {
      Authorization: `JWT ${token}`,
    },
  }
  return axios(options)
}

async function createLetterTemplate(token, name, content, category) {
  const options = {
    method: 'post',
    url: `${BASE_URL}/admin/letterTemplate`,
    headers: {
      Authorization: `JWT ${token}`,
    },
    data: {
      name,
      content,
      category,
    },
  }
  return axios(options)
}

async function listLetterTemplate(token, limit, skip) {
  const options = {
    method: 'get',
    url: `${BASE_URL}/admin/letterTemplate`,
    headers: {
      Authorization: `JWT ${token}`,
    },
    params: {
      limit,
      skip,
    },
  }
  return axios(options)
}

async function updateLetterTemplate(token, letterTemplateId, name, content) {
  const options = {
    method: 'put',
    url: `${BASE_URL}/admin/letterTemplate/${letterTemplateId}`,
    headers: {
      Authorization: `JWT ${token}`,
    },
    data: {
      name,
      content,
    },
  }
  return axios(options)
}

async function activateLetterTemplate(token, letterTemplateId) {
  const options = {
    method: 'put',
    url: `${BASE_URL}/admin/letterTemplate/${letterTemplateId}/active`,
    headers: {
      Authorization: `JWT ${token}`,
    },
  }
  return axios(options)
}

async function inactivateLetterTemplate(token, letterTemplateId) {
  const options = {
    method: 'put',
    url: `${BASE_URL}/admin/letterTemplate/${letterTemplateId}/inactive`,
    headers: {
      Authorization: `JWT ${token}`,
    },
  }
  return axios(options)
}

async function removeLetterTemplate(token, letterTemplateId) {
  const options = {
    method: 'delete',
    url: `${BASE_URL}/admin/letterTemplate/${letterTemplateId}`,
    headers: {
      Authorization: `JWT ${token}`,
    },
  }
  return axios(options)
}

async function createFaq(token, locale, title, content, priority, status) {
  const options = {
    method: 'post',
    url: `${BASE_URL}/admin/faq`,
    headers: {
      Authorization: `JWT ${token}`,
    },
    data: {
      locale,
      title,
      content,
      priority,
      status,
    },
  }
  return axios(options)
}

async function listFaq(token, limit, skip) {
  const options = {
    method: 'get',
    url: `${BASE_URL}/admin/faq`,
    headers: {
      Authorization: `JWT ${token}`,
    },
    params: {
      limit,
      skip,
    },
  }
  return axios(options)
}

async function updateFaq(
  token,
  faqId,
  locale,
  title,
  content,
  priority,
  status,
) {
  const options = {
    method: 'put',
    url: `${BASE_URL}/admin/faq/${faqId}`,
    headers: {
      Authorization: `JWT ${token}`,
    },
    data: {
      locale,
      title,
      content,
      priority,
      status,
    },
  }
  return axios(options)
}

async function removeFaq(token, faqId) {
  const options = {
    method: 'delete',
    url: `${BASE_URL}/admin/faq/${faqId}`,
    headers: {
      Authorization: `JWT ${token}`,
    },
  }
  return axios(options)
}

async function createTutorial(
  token,
  locale,
  title,
  kebab,
  content,
  description,
  images,
  keywords,
  priority,
  status,
) {
  const options = {
    method: 'post',
    url: `${BASE_URL}/admin/tutorial`,
    headers: {
      Authorization: `JWT ${token}`,
    },
    data: {
      locale,
      title,
      kebab,
      content,
      description,
      images,
      keywords,
      priority,
      status,
    },
  }
  return axios(options)
}

async function listTutorial(token, limit, skip) {
  const options = {
    method: 'get',
    url: `${BASE_URL}/admin/tutorial`,
    headers: {
      Authorization: `JWT ${token}`,
    },
    params: {
      limit,
      skip,
    },
  }
  return axios(options)
}

async function updateTutorial(
  token,
  tutorialId,
  locale,
  title,
  kebab,
  content,
  description,
  images,
  keywords,
  priority,
  status,
) {
  const options = {
    method: 'put',
    url: `${BASE_URL}/admin/tutorial/${tutorialId}`,
    headers: {
      Authorization: `JWT ${token}`,
    },
    data: {
      locale,
      title,
      kebab,
      content,
      description,
      images,
      keywords,
      priority,
      status,
    },
  }
  return axios(options)
}

async function removeTutorial(token, tutorialId) {
  const options = {
    method: 'delete',
    url: `${BASE_URL}/admin/tutorial/${tutorialId}`,
    headers: {
      Authorization: `JWT ${token}`,
    },
  }
  return axios(options)
}

async function createTnc(token, type, title, content, priority, status) {
  const options = {
    method: 'post',
    url: `${BASE_URL}/admin/tnc`,
    headers: {
      Authorization: `JWT ${token}`,
    },
    data: {
      type,
      title,
      content,
      priority,
      status,
    },
  }
  return axios(options)
}

async function listTnc(token, limit, skip) {
  const options = {
    method: 'get',
    url: `${BASE_URL}/admin/tnc`,
    headers: {
      Authorization: `JWT ${token}`,
    },
    params: {
      limit,
      skip,
    },
  }
  return axios(options)
}

async function updateTnc(token, tncId, title, content, priority, status) {
  const options = {
    method: 'put',
    url: `${BASE_URL}/admin/tnc/${tncId}`,
    headers: {
      Authorization: `JWT ${token}`,
    },
    data: {
      title,
      content,
      priority,
      status,
    },
  }
  return axios(options)
}

async function removeTnc(token, tncId) {
  const options = {
    method: 'delete',
    url: `${BASE_URL}/admin/tnc/${tncId}`,
    headers: {
      Authorization: `JWT ${token}`,
    },
  }
  return axios(options)
}

async function updateClinicStatus(token, clinicId, status) {
  const options = {
    method: 'put',
    url: `${BASE_URL}/admin/clinic/${clinicId}/status`,
    headers: {
      Authorization: `JWT ${token}`,
    },
    data: {
      status,
    },
  }
  return axios(options)
}

async function updateClinicFreeForever(token, clinicId, free) {
  const options = {
    method: 'put',
    url: `${BASE_URL}/admin/clinic/${clinicId}/free`,
    headers: {
      Authorization: `JWT ${token}`,
    },
    data: {
      free,
    },
  }
  return axios(options)
}

async function getClinicCurrentSubscription(token, clinicId) {
  const options = {
    method: 'get',
    url: `${BASE_URL}/admin/clinic/${clinicId}/subscription/current`,
    headers: {
      Authorization: `JWT ${token}`,
    },
  }
  return axios(options)
}

async function listUser(token, limit, skip) {
  const options = {
    method: 'get',
    url: `${BASE_URL}/admin/user`,
    headers: {
      Authorization: `JWT ${token}`,
    },
    params: {
      limit,
      skip,
    },
  }
  return axios(options)
}

async function getClinicDocumentLink(token, clinicId, documentId) {
  const options = {
    method: 'get',
    url: `${BASE_URL}/admin/clinic/${clinicId}/document/${documentId}`,
    headers: {
      Authorization: `JWT ${token}`,
    },
  }
  return axios(options)
}

async function listClinicDocument(token, clinicId, limit, skip) {
  const options = {
    method: 'get',
    url: `${BASE_URL}/admin/clinic/${clinicId}/document`,
    headers: {
      Authorization: `JWT ${token}`,
    },
    params: {
      limit,
      skip,
    },
  }
  return axios(options)
}

async function updateClinicTags(token, clinicId, tags) {
  const options = {
    method: 'put',
    url: `${BASE_URL}/admin/clinic/${clinicId}/tags`,
    headers: {
      Authorization: `JWT ${token}`,
    },
    data: { tags },
  }
  return axios(options)
}

async function updateUserAdmin(token, userId) {
  const options = {
    method: 'put',
    url: `${BASE_URL}/admin/user/${userId}/activate`,
    headers: {
      Authorization: `JWT ${token}`,
    },
  }
  return axios(options)
}

const apis = {
  login,
  forgotPassword,
  resetPassword,
  getSelf,
  listAdmin,
  createAdmin,
  updateAdmin,
  listClinic,
  listClinicUser,
  createClinicUser,
  updateClinicUser,
  searchClinic,
  getClinic,
  listSubscriptionPlan,
  checkHealth,
  checkOrenServerHealth,
  createLetterTemplate,
  listLetterTemplate,
  updateLetterTemplate,
  activateLetterTemplate,
  inactivateLetterTemplate,
  removeLetterTemplate,
  createFaq,
  listFaq,
  updateFaq,
  removeFaq,
  createTutorial,
  listTutorial,
  updateTutorial,
  removeTutorial,
  createTnc,
  listTnc,
  updateTnc,
  removeTnc,
  updateClinicStatus,
  updateClinicFreeForever,
  getClinicCurrentSubscription,
  listUser,
  listClinicDocument,
  getClinicDocumentLink,
  updateClinicTags,
  updateUserAdmin,
}

export default apis
