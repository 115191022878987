import { action, flow, makeAutoObservable, observable } from 'mobx'
import api from '~/src/api'

class UserStore {
  isSubmitting = false
  error = null

  constructor(rootStore) {
    this.rootStore = rootStore
    makeAutoObservable(this, {
      isSubmitting: observable,
      error: observable,
      reset: action,
      listAdmin: flow,
      createAdmin: flow,
      updateAdmin: flow,
      listClinicUser: flow,
      updateClinicUser: flow,
      listUser: flow,
    })
  }

  reset() {
    this.isSubmitting = false
    this.error = null
  }

  *listAdmin(
    limit,
    skip,
    sort,
    username,
    firstNameZh,
    lastNameZh,
    firstNameEn,
    lastNameEn,
    email,
    status,
    createdAtMin,
    createdAtMax,
  ) {
    this.isSubmitting = true
    const { commonStore } = this.rootStore
    const { token } = commonStore
    try {
      const response = yield api.listAdmin(
        token,
        limit,
        skip,
        sort,
        username,
        firstNameZh,
        lastNameZh,
        firstNameEn,
        lastNameEn,
        email,
        status,
        createdAtMin,
        createdAtMax,
      )
      this.error = null
      this.isSubmitting = false
      return Promise.resolve(response.data)
    } catch (error) {
      console.log(error)
      this.isSubmitting = false
      this.error = {
        errorCodes: error.response.data.errorCodes,
        messages: error.response.data.messages,
      }
      return Promise.reject()
    }
  }

  *createAdmin(
    username,
    firstNameZh,
    lastNameZh,
    firstNameEn,
    lastNameEn,
    email,
    password,
    status,
  ) {
    this.isSubmitting = true
    const { commonStore } = this.rootStore
    const { token } = commonStore
    try {
      yield api.createAdmin(
        token,
        username,
        firstNameZh,
        lastNameZh,
        firstNameEn,
        lastNameEn,
        email,
        password,
        status,
      )
      this.error = null
    } catch (error) {
      console.log(error)
      this.error = {
        errorCodes: error.response.data.errorCodes,
        messages: error.response.data.messages,
      }
    }
    this.isSubmitting = false
  }

  *updateAdmin(
    userId,
    username,
    firstNameZh,
    lastNameZh,
    firstNameEn,
    lastNameEn,
    email,
    status,
  ) {
    this.isSubmitting = true
    const { commonStore } = this.rootStore
    const { token } = commonStore
    try {
      yield api.updateAdmin(
        token,
        userId,
        username,
        firstNameZh,
        lastNameZh,
        firstNameEn,
        lastNameEn,
        email,
        status,
      )
      this.error = null
    } catch (error) {
      console.log(error)
      this.error = {
        errorCodes: error.response.data.errorCodes,
        messages: error.response.data.messages,
      }
    }
    this.isSubmitting = false
  }

  *listClinicUser(clinicId) {
    this.isSubmitting = true
    const { commonStore } = this.rootStore
    const { token } = commonStore
    try {
      const response = yield api.listClinicUser(token, clinicId)
      this.error = null
      this.isSubmitting = false
      return Promise.resolve(response.data)
    } catch (error) {
      console.log(error)
      this.error = {
        errorCodes: error.response.data.errorCodes,
        messages: error.response.data.messages,
      }
      this.isSubmitting = false
      return Promise.reject()
    }
  }

  *updateClinicUser(
    clinicId,
    staffId,
    username,
    firstNameZh,
    lastNameZh,
    firstNameEn,
    lastNameEn,
    email,
    role,
  ) {
    this.isSubmitting = true
    const { commonStore } = this.rootStore
    const { token } = commonStore
    try {
      yield api.updateClinicUser(
        token,
        clinicId,
        staffId,
        username,
        firstNameZh,
        lastNameZh,
        firstNameEn,
        lastNameEn,
        email,
        role,
      )
      this.error = null
      this.isSubmitting = false
      return Promise.resolve()
    } catch (error) {
      console.log(error)
      this.error = {
        errorCodes: error.response.data.errorCodes,
        messages: error.response.data.messages,
      }
      this.isSubmitting = false
      return Promise.reject()
    }
  }

  *listUser(limit, skip) {
    this.isSubmitting = true
    const { commonStore } = this.rootStore
    const { token } = commonStore
    try {
      const response = yield api.listUser(token, limit, skip)
      this.isSubmitting = false
      this.error = null
      return Promise.resolve(response.data)
    } catch (error) {
      console.log(error)
      this.isSubmitting = false
      this.error = {
        errorCodes: error.response.data.errorCodes,
        messages: error.response.data.messages,
      }
      return Promise.reject()
    }
  }
}

export default UserStore
